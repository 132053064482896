.logo {
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 1.5rem;
  z-index: 100;
  filter: contrast(0) brightness(0);
}

.canvas {
  width: 100%;
  height: 100dvh;
}

.glass {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1rem;

  width: 80vw;
  height: 80dvh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  border-radius: 34px;
  z-index: 2;

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-width: 200px;
    max-height: 80dvh;
    @media screen and (max-width: 768px) {
      position: absolute;
      top: -10px;
      transform: translate(0%, -50%);
      left: 0px;
      height: auto;
      width: 90px;
    }
  }

  .reader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-width: 200px;
    max-height: 80dvh;
    padding: 20px;
    @media screen and (max-width: 768px) {
      flex: 1;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    background-color: rgba(20, 0, 46, 0.84);
    border-top-right-radius: 34px;
    border-bottom-right-radius: 34px;
    @media screen and (max-width: 768px) {
      border-radius: 34px;
    }
    padding: 20px;
    h1 {
      font-size: 1.5rem;
      margin-bottom: 20px;
      margin-top: 40px;
    }
    .description {
      font-size: 12px;
    }
    .info {
      display: flex;
      flex: 1;
      flex-direction: column;
      .infoitem {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        padding-right: 20px;
        padding-top: 10px;
      }
    }
    .btn {
      margin-top: 20px;
      .btnstart {
        padding: 10px;
        border-radius: 10px;
        background-color: #7357ff;
        width: 136px;
        color: white;
        border: 0px;
        margin-top: 30px;
      }
    }
    .footer {
      display: flex;
      flex: 1 1;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      font-size: 14px;
      width: 100%;
      align-items: flex-end;
      .paginator {
        margin: 10px;
        .btnstart {
          padding: 10px;
          border-radius: 10px;
          background-color: transparent;
          width: 36px;
          color: white;
          margin-left: 5px;
          border: white solid 1px;
        }
      }
    }
  }

  .toc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    max-width: 27vw;
    background-color: rgba(20, 0, 46, 0.84);
    border-top-right-radius: 34px;
    border-bottom-right-radius: 34px;
    @media screen and (max-width: 768px) {
      display: none;
    }
    padding: 10px;
    padding-top: 20px;
    padding-left: 15px;
    max-height: 80dvh;
    .toccontainer {
      margin-top: 5px;
      max-height: 70dvh;
      overflow-y: auto;
      overflow-x: auto;
      scrollbar-width: thin;
      scrollbar-color: #30095c #f2fdff;
      max-width: 27vw;
      margin-right: 10px;
      display: flex;
      flex: 1;
      width: 100%;
    }

    /* Chrome, Edge and Safari */
    .toccontainer::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
    .toccontainer::-webkit-scrollbar-track {
      border-radius: 0px;
      background-color: #f2fdff;
    }

    .toccontainer::-webkit-scrollbar-track:hover {
      background-color: #b8c0c2;
    }

    .toccontainer::-webkit-scrollbar-track:active {
      background-color: #b8c0c2;
    }

    .toccontainer::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #30095c;
      border: 1px solid #ffffff;
    }

    .toccontainer::-webkit-scrollbar-thumb:hover {
      background-color: #3505a3;
    }

    .toccontainer::-webkit-scrollbar-thumb:active {
      background-color: #440da3;
    }

    h5 {
      margin-bottom: 20px;
      margin-top: 10px;
      max-width: 19vw;
    }
    .description {
      font-size: 12px;
    }
    .info {
      display: flex;
      flex-direction: row;
      .infoitem {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        padding-right: 20px;
        padding-top: 10px;
      }
    }
    .btn {
      margin-top: 20px;
      .btnstart {
        padding: 10px;
        border-radius: 10px;
        background-color: #7357ff;
        width: 136px;
        color: white;
        border: 0px;
        margin-top: 30px;
      }
    }
    .ca {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      span {
        font-weight: 100!important;
        font-size: 10px;
      }
    }
    .footer {
      display: flex;
      flex: 1 1;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      font-size: 14px;
      width: 100%;
      align-items: flex-end;
      .paginator {
        margin: 10px;
        .btnstart {
          padding: 10px;
          border-radius: 10px;
          background-color: transparent;
          width: 36px;
          color: white;
          margin-left: 5px;
          border: white solid 1px;
        }
      }
    }
  }
}
.toc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  max-width: 300px;
  background-color: rgba(20, 0, 46, 0.84);
  border-top-right-radius: 34px;
  border-bottom-right-radius: 34px;
  @media screen and (max-width: 768px) {
    max-width: 80vw;
  }
  padding: 10px;
  padding-top: 20px;
  padding-left: 15px;
  max-height: 80dvh;
  .toccontainer {
    margin-top: 5px;
    max-height: 80dvh;
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #30095c #f2fdff;
    max-width: 300px;
    margin-right: 10px;
    display: flex;
    flex: 1;
    width: 100%;
  }

  /* Chrome, Edge and Safari */
  .toccontainer::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  .toccontainer::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #f2fdff;
  }

  .toccontainer::-webkit-scrollbar-track:hover {
    background-color: #b8c0c2;
  }

  .toccontainer::-webkit-scrollbar-track:active {
    background-color: #b8c0c2;
  }

  .toccontainer::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #30095c;
    border: 1px solid #ffffff;
  }

  .toccontainer::-webkit-scrollbar-thumb:hover {
    background-color: #3505a3;
  }

  .toccontainer::-webkit-scrollbar-thumb:active {
    background-color: #440da3;
  }

  h5 {
    margin-bottom: 20px;
    margin-top: 10px;
    max-width: 19vw;
  }
  .description {
    font-size: 12px;
  }
  .info {
    display: flex;
    flex-direction: row;
    .infoitem {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      padding-right: 20px;
      padding-top: 10px;
    }
  }
  .btn {
    margin-top: 20px;
    .btnstart {
      padding: 10px;
      border-radius: 10px;
      background-color: #7357ff;
      width: 136px;
      color: white;
      border: 0px;
      margin-top: 30px;
    }
  }
  .footer {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 14px;
    width: 100%;
    align-items: flex-end;
    .paginator {
      margin: 10px;
      .btnstart {
        padding: 10px;
        border-radius: 10px;
        background-color: transparent;
        width: 36px;
        color: white;
        margin-left: 5px;
        border: white solid 1px;
      }
    }
  }
}
.glass::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.5);
  background: rgba(
    255,
    255,
    255,
    0.05
  ); /* Semi-transparent white for stronger effect */
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-radius: 34px;
  /* This places the background beneath the content */
  z-index: -1;
}

.mute {
  position: absolute;
  z-index: 100001;
  top: 10px;
  right: 10px;
}

.avatar {
  position: absolute;
  z-index: 100001;
  top: 10px;
  right: 60px;
}
